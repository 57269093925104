<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Selected proxies
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="d-flex my-2 align-center justify-space-between">
          <TableSearch
            v-model="tableOptions.search"

            :show-filters.sync="showFilters"

            :check-filter-toggle="checkFilterToggle"
          />
        </div>
        <v-data-table
          :items="proxies"
          :headers="itemHeaders"
          hide-default-footer
          :search="tableOptions.search"
          :options.sync="tableOptions"
          class="mt-2"
          :footer-props="{
            'items-per-page-options': pages,
            'show-first-last-page': true,
            'show-current-page': true,
          }"
        >
          <template
            v-slot:top
          >
            <v-layout
              v-show="showFilters"
              class="pa-4 pt-2"
            >
              <v-row>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.status"
                    label="Status"
                    placeholder="Select status"
                    hide-details
                    clearable
                    :items="statusOptions"
                    class="pa-0 ma-0"
                  ></v-select>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.value="{ item }">
            <v-skeleton-loader
              :loading="item.loading"
              type="table-cell"
            >
              {{ item.value }}
            </v-skeleton-loader>
          </template>
          <template v-slot:item.status="{ item }">
            <v-skeleton-loader
              :loading="item.loading"
              type="table-cell"
            >
              <v-chip
                :color="StatusesColorMap[item.status] && StatusesColorMap[item.status].color"
              >
                {{ StatusesColorMap[item.status] && StatusesColorMap[item.status].text }}
              </v-chip>
            </v-skeleton-loader>
          </template>
          <template v-slot:item.action="{ item,index}">
            <v-btn
              icon
              class="ml-2"
              @click="$emit('unselect',index)"
            >
              <v-icon dense>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.mobile="{ value }">
            <TrueFalse :value="value"></TrueFalse>
          </template>
          <template v-slot:footer="{ props, on }">
            <TableFooter
              v-bind="props"
              v-on="on"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import {
  mdiClose, mdiAccount, mdiCheckCircleOutline, mdiLockOutline, mdiCancel, mdiFilter, mdiMagnify,
} from '@mdi/js'
import {
  StatusesColorMap,
} from '@core/constants'
import _cloneDeep from 'lodash/cloneDeep'
import TrueFalse from '@/components/TrueFalse.vue'
import ConfirmDialog from '../ConfirmDialog.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

const defaultOptions = {
  search: '',
  status: '',
  sortBy: [],
  sortDesc: [],
  page: 1,
  itemsPerPage: localStorage.getItem('itemsPerPage') || 50,
}

export default {
  components: {
    ConfirmDialog, TrueFalse, TableFooter, TableSearch,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => ({}),
    },
    itemHeaders: {
      type: Array,
      default: () => ({}),
    },
    roles: {
      type: Array,
      default: () => (null),
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiAccount,
        mdiCheckCircleOutline,
        mdiLockOutline,
        mdiCancel,
        mdiFilter,
        mdiMagnify,
      },
      isOpen: false,
      pages: [50, 100, 200],
      showFilters: false,
      tableOptions: {
        ...defaultOptions,
      },
      StatusesColorMap,
      statusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'not_active', text: 'Not Active' },
        { value: 'expired', text: 'Expired' },
        { value: 'banned', text: 'Banned' },
        { value: 'rate_limit', text: 'Rate limit' },
      ],
    }
  },
  computed: {
    proxies() {
      if (this.tableOptions.status && this.tableOptions.status.length > 0) {
        return this.selectedItems.filter(el => el.status === this.tableOptions.status)
      }

      return this.selectedItems
    },
  },
  methods: {
    open() {
      this.isOpen = true
    },
    reset() {
      this.isOpen = false
      this.showFilters = false
      this.tableOptions = {
        ...defaultOptions,
      }
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if (!!value !== false) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
  },
}
</script>

<style scoped>
label span{
  font-size: 0.7rem;
}
</style>
