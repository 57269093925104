<template>
  <div>
    <v-checkbox
      v-model="localValue"
      class="d-none"
      :rules="rules"
    />
    <v-btn
      outlined
      :loading="loading"
      :disabled="disabled"
      color="accent"
      link
      @click="tryTestProxy"
    >
      Check
    </v-btn>
    <v-icon
      v-if="localValue === true"
      class="ml-4"
      size="large"
      color="success"
    >
      {{ icons.mdiCheck }}
    </v-icon>
    <v-icon
      v-else-if="localValue === false"
      class="ml-4"
      size="large"
      color="error"
    >
      {{ icons.mdiAlertCircleOutline }}
    </v-icon>
  </div>
</template>

<script>
import ProxyApi from '@core/api/proxy'
import {
  mdiCheck,
  mdiAlertCircleOutline,
} from '@mdi/js'

export default {
  props: {
    url: { type: String, default: '' },
    value: { type: Boolean, default: null },
    rules: { type: Array, default: () => ([]) },
    disabled: Boolean,
  },
  data: () => ({
    loading: false,
    icons: {
      mdiCheck,
      mdiAlertCircleOutline,
    },
  }),
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async tryTestProxy() {
      this.loading = true
      this.localValue = null
      try {
        const { data } = await ProxyApi.checkProxy({ proxies: [this.url] })
        this.localValue = data[this.url]
        this.$emit('success', true)
      } catch (err) {
        this.$message.error(err)
      }
      this.loading = false
    },
  },
}
</script>

<style>

</style>
