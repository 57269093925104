var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mr-4 mb-5",on:{"click":function () {
      if(_vm.selectedProxy.length>0){
        _vm.openCheckProxyModal()
      }else{
        _vm.$message.info('No proxies selected')
      }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiLanCheck)+" ")]),_vm._v("check proxy ")],1),_c('v-btn',{staticClass:"mr-4 mb-5",on:{"click":function () {
      if(_vm.selectedProxy.length>0){
        _vm.openExportProxyModal()
      }else{
        _vm.$message.info('No proxies selected')
      }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_vm._v("Export proxies ")],1),_c('v-card',[_c('v-card-title',[(_vm.selectedProxy.length)?_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.tryDeleteManyProxy}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteForever)+" ")]),_vm._v("Delete ")],1):_vm._e(),_c('v-spacer'),_c('TableSearch',{attrs:{"show-filters":_vm.showFilters,"check-filter-toggle":_vm.checkFilterToggle},on:{"update:showFilters":function($event){_vm.showFilters=$event},"update:show-filters":function($event){_vm.showFilters=$event}},model:{value:(_vm.tableOptions.search),callback:function ($$v) {_vm.$set(_vm.tableOptions, "search", $$v)},expression:"tableOptions.search"}}),_c('v-spacer'),_c('AddNewProxy',{on:{"submitted":function () { return _vm.tryFetchProxies(); }}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","show-select":"","mobile-breakpoint":0,"item-key":"id","loading-text":"Loading proxies ...","loading":_vm.loading,"items":_vm.proxies,"server-items-length":_vm.total,"headers":_vm.proxiesHeaders,"options":_vm.tableOptions,"footer-props":{
          'items-per-page-options': _vm.pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"label":"Status","placeholder":"Select status","hide-details":"","clearable":"","items":_vm.statusOptions},model:{value:(_vm.tableOptions.status),callback:function ($$v) {_vm.$set(_vm.tableOptions, "status", $$v)},expression:"tableOptions.status"}})],1)],1)],1)]},proxy:true},{key:"item.mobile",fn:function(ref){
        var value = ref.value;
return [_c('TrueFalse',{attrs:{"value":value}})]}},{key:"item.value",fn:function(ref){
        var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_c('v-chip',{attrs:{"color":_vm.StatusesColorMap[item.status] && _vm.StatusesColorMap[item.status].color}},[_vm._v(" "+_vm._s(_vm.StatusesColorMap[item.status] && _vm.StatusesColorMap[item.status].text)+" ")])],1)]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('ChangeIP',{attrs:{"proxy":item}}),_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openProxyEditModal(item)},"delete":function($event){return _vm.tryDeleteProxy(item.id)}}})]}},{key:"footer",fn:function(ref){
        var props = ref.props;
        var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on),[(_vm.selectedProxy.length)?_c('v-btn',{staticClass:"text-body-2 text-capitalize ml-2 primary--text",attrs:{"depressed":""},on:{"click":function (){
                _vm.openSelectedProxyModal()
              }}},[_c('span',[_vm._v("Selected: "),_c('span',{staticClass:"text-body-2 ml-1"},[_vm._v(_vm._s(_vm.selectedProxy.length))])])]):_vm._e()],1)]}}]),model:{value:(_vm.selectedProxy),callback:function ($$v) {_vm.selectedProxy=$$v},expression:"selectedProxy"}})],1),_c('ProxyEditModal',{ref:"editProxyModal",on:{"update":_vm.tryUpdateProxy}}),_c('TestSelectedProxy',{ref:"testSelectedProxy",attrs:{"selected-proxies":_vm.selectedProxy},on:{"tested":_vm.tryFetchProxies}}),_c('SelectedProxies',{ref:"selectedAccounts",attrs:{"selected-items":_vm.selectedProxy,"item-headers":_vm.proxiesHeaders,"items-name":"proxies"},on:{"unselect":function (data){_vm.selectedProxy.splice(data,1)}}}),_c('ExportProxy',{ref:"exportProxy",attrs:{"selected-proxy":_vm.selectedProxy}}),_c('ConfirmDialog',{ref:"deleteProxyConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var agree = ref.agree;
              var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete proxy")]),_c('v-card-text',[_vm._v(" Do you really want to delete proxy ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }