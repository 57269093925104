<template>
  <v-btn
    v-if="proxy.mobile"
    color="primary"
    small
    outlined
    :loading="loading"
    class="mr-4"
    @click="tryCheckProxy"
  >
    Change IP
  </v-btn>
</template>

<script>
import ProxyApi from '@core/api/proxy'

export default {
  props: {
    proxy: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async tryCheckProxy() {
      try {
        this.loading = true
        await ProxyApi.changeIp({ proxy: this.proxy })
        this.$message.success('Ip changed successfully')
      } catch (error) {
        this.$message.error(error)
        this.$message.error(error)
      }
      this.loading = false
    },
  },
}
</script>

<style>

</style>
