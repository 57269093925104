<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    scrollable
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Test Connection
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-virtual-scroll
          :items="selectedProxies"
          item-height="37"
          height="500"
        >
          <template v-slot:default="{ item }">
            <h4
              :key="item.id"
              class="mb-4"
            >
              <TrueFalse
                v-if="proxies"
                :value="proxies[item.value]"
              />
              {{ item.value }}
            </h4>
          </template>
        </v-virtual-scroll>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          outlined
          :loading="loading"
          color="accent"
          link
          @click="tryTestProxies"
        >
          Check All
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'

import cloneDeep from 'lodash/cloneDeep'
import ProxyApi from '@core/api/proxy'
import chunk from 'lodash/chunk'
import ConfirmDialog from '../ConfirmDialog.vue'
import TrueFalse from '../TrueFalse.vue'

export default {
  components: { ConfirmDialog, TrueFalse },
  props: {
    selectedProxies: {
      type: Array,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
      mdiCheck,
      mdiAlertCircleOutline,
    },
    isOpen: false,
    loading: false,
    disabled: true,
    proxies: null,
  }),
  computed: {
    proxiesUrls() {
      return this.selectedProxies.map(el => el.value)
    },
  },
  methods: {
    open(proxy) {
      this.isOpen = true
      this.initProxy = cloneDeep(proxy)
      this.proxy = cloneDeep(proxy)
    },
    reset() {
      this.isOpen = false
      this.proxies = null
      this.proxy = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    async testProxies(proxies) {
      const { data } = await ProxyApi.checkProxy({ proxies })
      this.proxies = { ...this.proxies, ...data }
      this.loading = false
      this.disabled = false
    },
    tryTestProxies() {
      try {
        this.loading = true
        const proxiesChunk = chunk(this.proxiesUrls, 20)
        proxiesChunk.forEach(item => {
          this.testProxies(item)
        })
        this.$emit('tested')
      } catch (err) {
        this.$message.error(err)
      }
    },
  },
}
</script>

<style scoped>
  .status__btn{
  cursor: default;
  color: rgba(231, 227, 252, 0.68);
  }
  .status__btn:hover{
  color: rgba(231, 227, 252,0.68);
  }
  .theme--dark.v-btn:hover::before {
    opacity: 0;
}
</style>
