<template>
  <div>
    <v-btn
      class="mr-4 mb-5"
      @click="() => {
        if(selectedProxy.length>0){
          openCheckProxyModal()
        }else{
          $message.info('No proxies selected')
        }}"
    >
      <v-icon left>
        {{ icons.mdiLanCheck }}
      </v-icon>check proxy
    </v-btn>
    <v-btn
      class="mr-4 mb-5"
      @click="() => {
        if(selectedProxy.length>0){
          openExportProxyModal()
        }else{
          $message.info('No proxies selected')
        }}"
    >
      <v-icon left>
        {{ icons.mdiDownload }}
      </v-icon>Export proxies
    </v-btn>
    <v-card>
      <v-card-title>
        <v-btn
          v-if="selectedProxy.length"
          text
          color="error"
          @click="tryDeleteManyProxy"
        >
          <v-icon left>
            {{ icons.mdiDeleteForever }}
          </v-icon>Delete
        </v-btn>
        <v-spacer />
        <TableSearch
          v-model="tableOptions.search"

          :show-filters.sync="showFilters"

          :check-filter-toggle="checkFilterToggle"
        />
        <v-spacer />
        <AddNewProxy
          @submitted="() => tryFetchProxies()"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selectedProxy"
          hide-default-footer
          show-select
          :mobile-breakpoint="0"
          item-key="id"
          loading-text="Loading proxies ..."
          :loading="loading"
          :items="proxies"
          :server-items-length="total"
          :headers="proxiesHeaders"
          :options.sync="tableOptions"
          :footer-props="{
            'items-per-page-options': pages,
            'show-first-last-page': true,
            'show-current-page': true,
          }"
        >
          <template
            v-slot:top
          >
            <v-layout
              v-show="showFilters"
              class="pa-4"
            >
              <v-row>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.status"
                    label="Status"
                    placeholder="Select status"
                    hide-details
                    clearable
                    :items="statusOptions"
                    class="pa-0 ma-0"
                  ></v-select>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.mobile="{ value }">
            <TrueFalse :value="value"></TrueFalse>
          </template>

          <!-- SELECT ROW-->

          <template v-slot:item.value="{ item }">
            <v-skeleton-loader
              :loading="item.loading"
              type="table-cell"
            >
              {{ item.value }}
            </v-skeleton-loader>
          </template>
          <template v-slot:item.status="{ item }">
            <v-skeleton-loader
              :loading="item.loading"
              type="table-cell"
            >
              <v-chip
                :color="StatusesColorMap[item.status] && StatusesColorMap[item.status].color"
              >
                {{ StatusesColorMap[item.status] && StatusesColorMap[item.status].text }}
              </v-chip>
            </v-skeleton-loader>
          </template>
          <template v-slot:item.action="{ item }">
            <ChangeIP
              :proxy="item"
            />
            <TableActions
              :loading="item.loading"
              @edit="openProxyEditModal(item)"
              @delete="tryDeleteProxy(item.id)"
            />
          </template>
          <template v-slot:footer="{ props, on }">
            <TableFooter
              v-bind="props"
              v-on="on"
            >
              <v-btn
                v-if="selectedProxy.length"
                depressed
                class="text-body-2 text-capitalize ml-2 primary--text"
                @click="()=>{
                  openSelectedProxyModal()
                }"
              >
                <span>Selected: <span class="text-body-2 ml-1">{{ selectedProxy.length }}</span></span>
              </v-btn>
            </TableFooter>
          </template>
        </v-data-table>
      </v-card-text>

      <ProxyEditModal
        ref="editProxyModal"
        @update="tryUpdateProxy"
      />
      <TestSelectedProxy
        ref="testSelectedProxy"
        :selected-proxies="selectedProxy"
        @tested="tryFetchProxies"
      />
      <SelectedProxies
        ref="selectedAccounts"
        :selected-items="selectedProxy"
        :item-headers="proxiesHeaders"
        items-name="proxies"
        @unselect="(data)=>{selectedProxy.splice(data,1)}"
      >
      </SelectedProxies>
      <ExportProxy
        ref="exportProxy"
        :selected-proxy="selectedProxy"
      >
      </ExportProxy>
      <ConfirmDialog
        ref="deleteProxyConfirm"
        max-width="400"
      >
        <template v-slot="{agree, cancel}">
          <v-card>
            <v-card-title>Delete proxy</v-card-title>
            <v-card-text>
              Do you really want to delete proxy
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                @click="agree"
              >
                <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
                Delete
              </v-btn>
              <v-btn
                @click="cancel"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </ConfirmDialog>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiFilter,
  mdiMagnify,
  mdiLanCheck,
  mdiDownload,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import { StatusesColorMap } from '@core/constants'
import _cloneDeep from 'lodash/cloneDeep'
import ProxyApi from '@core/api/proxy'
import store from '@/store'
import proxyStoreModule, { PROXY_MODULE_NAME } from '@/store/modules/proxyStoreModule'
import AddNewProxy from '@/components/proxy/AddNew.vue'
import TestSelectedProxy from '@/components/proxy/TestSelectedProxy.vue'
import ProxyEditModal from '@/components/proxy/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TrueFalse from '@/components/TrueFalse.vue'
import ChangeIP from '@/components/proxy/ChangeIP.vue'
import TableFooter from '@/components/TableFooter.vue'
import SelectedProxies from '@/components/proxy/SelectedProxies.vue'
import TableSearch from '@/components/TableSearch.vue'
import ExportProxy from '@/components/proxy/ExportProxy.vue'

export default {
  components: {
    AddNewProxy,
    ProxyEditModal,
    ConfirmDialog,
    TableActions,
    TrueFalse,
    ChangeIP,
    TableFooter,
    TestSelectedProxy,
    SelectedProxies,
    TableSearch,
    ExportProxy,
  },
  data() {
    return {
      loading: true,
      total: 50,
      showFilters: false,
      StatusesColorMap,
      tableOptions: {
        search: this.$route.query.search || '',
        status: this.$route.query.status || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      selectedProxy: [],
      pages: [50, 100, 200],
      statusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'not_active', text: 'Not Active' },
        { value: 'expired', text: 'Expired' },
        { value: 'banned', text: 'Banned' },
        { value: 'rate_limit', text: 'Rate limit' },
      ],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
        mdiFilter,
        mdiLanCheck,
        mdiDownload,
      },
      proxiesHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          align: 'start',
        },
        {
          text: 'URL',
          value: 'value',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          text: 'Mobile',
          value: 'mobile',
          sortable: true,
          align: 'start',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
          align: 'start',
        },
        {
          text: 'Expired At',
          value: 'expired_at',
          sortable: true,
          align: 'start',
        },
        {
          align: 'end',
          value: 'action',
          cellClass: 'd-flex align-center justify-end',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      proxies(state) {
        return state[PROXY_MODULE_NAME]?.proxies.filter(value => !this.filterOptions || value.status === this.filterOptions)
      },
    }),
    mappedOptions() {
      return this.$route.query
    },
    filterOptions: {
      get() {
        return this.tableOptions.filterStatus
      },
      set(value) {
        this.$set(this.tableOptions, 'filterStatus', value)
      },
    },
    itemHeaders() {
      return this.proxiesHeaders.map(item => ({ ...item, sortable: false, filterable: false }))
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchProxies(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(PROXY_MODULE_NAME)) {
      store.registerModule(PROXY_MODULE_NAME, proxyStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchProxies(dispatch, payload) {
        return dispatch(`${PROXY_MODULE_NAME}/fetchProxies`, payload)
      },
      updateProxy(dispatch, payload) {
        return dispatch(`${PROXY_MODULE_NAME}/updateProxy`, payload)
      },
      removeProxy(dispatch, payload) {
        return dispatch(`${PROXY_MODULE_NAME}/removeProxy`, payload)
      },
    }),

    ...mapMutations({
      commitSetProxies(commit, payload) {
        return commit(`${PROXY_MODULE_NAME}/SET_PROXIES `, payload)
      },
      commitEditProxy(commit, payload) {
        return commit(`${PROXY_MODULE_NAME}/EDIT_PROXY`, payload)
      },
      commitDeleteProxy(commit, payload) {
        return commit(`${PROXY_MODULE_NAME}/REMOVE_PROXY`, payload)
      },
    }),

    async tryFetchProxies(params = this.mappedOptions) {
      this.loading = true

      const { data } = await this.fetchProxies(params)

      this.total = data.total
      this.loading = false
    },

    async tryDeleteManyProxy() {
      this.$refs.deleteProxyConfirm.open().then(async () => {
        this.loading = true
        await ProxyApi.removeMany(this.selectedProxy.map(el => el.id))
        this.loading = false
        this.tryFetchProxies()
      })
    },

    async tryDeleteProxy(id) {
      this.$refs.deleteProxyConfirm.open().then(() => {
        this.commitEditProxy({ id, loading: true })
        this.removeProxy({ id }).then(() => {
          this.commitDeleteProxy({ id })
          this.selectedProxy = this.selectedProxy.filter(el => el.id !== id)
          this.$message.success('Proxy deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateProxy(value) {
      this.commitEditProxy({ ...value, loading: true })
      const { data } = await this.updateProxy({ proxy: value })
      this.commitEditProxy(data)
    },

    openProxyEditModal(proxy) {
      this.$refs.editProxyModal.open(proxy)
    },
    openCheckProxyModal(proxy) {
      this.$refs.testSelectedProxy.open(proxy)
    },
    openExportProxyModal(proxy) {
      this.$refs.exportProxy.open(proxy)
    },
    openSelectedProxyModal() {
      this.$refs.selectedAccounts.open()
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if (!!value !== false) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
  },
}
</script>
