<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        :step="1"
      >
        URIs
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :step="2"
      >
        Check
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content
        class="pt-0"
        :step="1"
      >
        <v-form ref="formUrl">
          <v-tabs
            v-model="proxyMode"
            grow
            class="mb-4"
          >
            <v-tab
              :disabled="loading"
            >
              List
            </v-tab>
            <v-tab
              :disabled="loading"
            >
              Manual
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="proxyMode"
            class="mt-0"
          >
            <v-tab-item>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="parseFormat"
                    label="Format"
                    class="mb-2"
                    hide-details="auto"
                    :items="urlFormats"
                  />

                  <label for="protocol">Protocol</label>
                  <v-select
                    id="protocol"
                    v-model="protocol"
                    class="mb-2"
                    :items="supportedProtocol"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Enter protocol"
                    :rules="proxyMode === 0 ? [required] : []"
                    @change="() => urlChecked = null"
                  ></v-select>
                  <label for="proxyValue">Proxy URI </label>
                  <v-textarea
                    id="proxyValue"
                    v-model="proxyValue"
                    type="textarea"
                    outlined
                    dense
                    hide-details="auto"
                    :placeholder="`Example ${parseFormat}`"
                    :disabled="loading"
                    :rules="proxyMode === 0 ? [required] : []"
                    @change="() => urlChecked = null"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label for="protocol">Protocol</label>
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                >
                  <v-select
                    id="protocol"
                    v-model="protocol"
                    :items="supportedProtocol"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Enter protocol"
                    :rules="proxyMode === 0 ? [required] : []"
                    @change="() => urlChecked = null"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label for="host">Proxy Host</label>
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                >
                  <v-text-field
                    id="host"
                    v-model="host"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Enter host"
                    :disabled="loading"
                    :rules="proxyMode === 1 ? [required] : []"
                    @change="() => urlChecked = null"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label for="port">Port</label>
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                >
                  <v-text-field
                    id="port"
                    v-model="port"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Enter port"
                    :disabled="loading"
                    :rules="proxyMode === 1 ? [required] : []"
                    @change="() => urlChecked = null"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label for="host">Username</label>
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                >
                  <v-text-field
                    v-model="username"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Enter username"
                    :disabled="loading"
                    :rules="[]"
                    @change="() => urlChecked = null"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <label for="host">Password</label>
                </v-col>

                <v-col
                  cols="12"
                  md="9"
                >
                  <v-text-field
                    v-model="password"
                    outlined
                    dense
                    hide-details="auto"
                    type="password"
                    placeholder="Enter password"
                    :disabled="loading"
                    :rules="[]"
                    @change="() => urlChecked = null"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-stepper-content>
      <v-stepper-content
        class="pt-0"
        :step="2"
      >
        <v-row>
          <v-col>
            <label for="generate-name">Generate names for all proxies</label>
            <v-text-field
              id="generate-name"
              v-model="proxiesName"
              placeholder="Enter name"
              dense
            ></v-text-field>
          </v-col>
          <v-col class="d-flex align-center">
            <v-btn
              color="primary"
              :disabled="proxiesName.length===0"
              @click="generateNames"
            >
              Generate
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="formCheck">
          <v-data-table
            item-key="value"
            show-expand
            :items-per-page="-1"
            :headers="previewHeaders"
            :items="sortProxies"
            hide-default-footer
          >
            <template v-slot:item.name="{item}">
              <v-text-field
                v-model="item.name"
                placeholder="Name"
              />
            </template>
            <template v-slot:item.url="{item}">
              <v-text-field
                v-model="item.url"
                placeholder="URL"
              />
            </template>
            <template v-slot:item.protocol="{item}">
              <v-select
                v-model="item.protocol"
                placeholder="Protocol"
                :items="supportedProtocol"
              />
            </template>
            <template v-slot:header.action>
              <v-btn
                color="primary"
                small
                :loading="checkLoading"
                @click="checkProxy"
              >
                Check Proxies
              </v-btn>
            </template>
            <template v-slot:item.action="{item, index}">
              <div class="d-flex justify-end">
                <TrueFalse
                  v-show="!checkLoading"
                  :value="item.checked"
                />
                <v-btn
                  icon
                  @click="() => removeProxy(index)"
                >
                  <v-icon>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:expanded-item="{item}">
              <td colspan="3">
                <label for="user_agent">Expire Date</label>

                <DatePicker
                  v-model="item.expireDate"
                  :disabled="loading"
                />

                <v-switch
                  id="mobile"
                  v-model="item.mobile"
                  label="Mobile"
                  :disabled="loading"
                />
                <template v-if="item.mobile">
                  <label for="changeIpUrl">Change IP Url</label>

                  <v-text-field
                    id="changeIpUrl"
                    v-model="item.changeIpUrl"
                    :disabled="loading"
                  />
                </template>
              </td>
            </template>
          </v-data-table>
        </v-form>
      </v-stepper-content>
    </v-stepper-items>
    <v-spacer />
    <v-layout d-flex>
      <v-btn
        :disabled="checkLoading || loading"
        @click="step--"
      >
        Back
      </v-btn>
      <v-spacer />
      <v-alert
        v-show="step === 2 && !checkLoading && (!formCheck.validate() || proxies.some(el => !el.checked))"
        class="mr-4"
        dense
        text
        border="left"
        type="warning"
      >
        Remove or edit invalid proxies
      </v-alert>
      <v-tooltip
        v-if="step === 2"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            class="mr-4"
            :disabled="proxies.every(el => el.checked)"
            @click="removeInvalidProxies"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
          </v-btn>
        </template>
        <span>Remove invalid proxies</span>
      </v-tooltip>
      <v-btn
        :disabled="step === 2 && (!formCheck.validate() || proxies.some(el => !el.checked) || checkLoading)"
        color="primary"
        :loading="loading"
        @click="tryNext"
      >
        {{ step != 3 ? 'Next' : 'Create' }}
      </v-btn>
    </v-layout>
  </v-stepper>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  mdiAlertCircleOutline, mdiCalendar, mdiCheck, mdiDelete, mdiDeleteForever,
} from '@mdi/js'
import ProxyApi from '@core/api/proxy'
import { required, isProxyUrl, isTrue } from '@core/utils/validation'

import DatePicker from '../DatePicker.vue'
import TrueFalse from '../TrueFalse.vue'

export default {
  components: {
    DatePicker,
    TrueFalse,
  },
  props: {
    loading: Boolean,
  },
  setup(props, { emit }) {
    const formUrl = ref(null)
    const formOptions = ref(null)
    const formCheck = ref(null)
    const validate = () => formUrl.value.validate()

    const reset = () => {
      formUrl.value.reset()
      formOptions.value.reset()
      formCheck.value.reset()
    }
    const step = ref(1)
    const proxies = ref([])
    const previewHeaders = [
      {
        value: 'name',
        text: 'Name',
      },
      {
        value: 'url',
        text: 'URL',
        filterable: true,
      },
      {
        value: 'action',
        sortable: false,
        align: 'end',
      },
    ]
    const supportedProtocol = [{
      text: 'HTTP',
      value: 'http:',
    }, {
      text: 'HTTPS',
      value: 'https:',
    }, {
      text: 'SOP2',
      value: 'sop2:',
    }, {
      text: 'SOP3',
      value: 'sop3:',
    }, {
      text: 'socks4',
      value: 'socks4:',
    }, {
      text: 'socks5',
      value: 'socks5:',
    }]
    const proxyValue = ref('')
    const expireDate = ref()
    const changeIpUrl = ref('')
    const host = ref('')
    const protocol = ref('http:')
    const port = ref('')
    const username = ref('')
    const password = ref('')
    const name = ref('')
    const proxyMode = ref(0)
    const urlChecked = ref(null)
    const mobile = ref(false)
    const checkLoading = ref(false)
    const proxiesName = ref('')

    const parseFormat = ref('login:password@ip:port')
    const urlFormats = [
      'ip:port:login:password',
      'ip:port@login:password',
      'ip:port|login:password',
      'login:password@ip:port',
      'login:password:ip:port',
      'ip:port',
    ]

    const parseUrl = (url, format) => {
      let match
      switch (format) {
        case ('ip:port:login:password'):
          match = url.match(/(?<ip>.*):(?<port>.*):(?<login>.*):(?<password>.*)/)
          break
        case ('ip:port@login:password'):
          match = url.match(/(?<ip>.*):(?<port>.*)@(?<login>.*):(?<password>.*)/)
          break
        case ('ip:port|login:password'):
          match = url.match(/(?<ip>.*):(?<port>.*)\|(?<login>.*):(?<password>.*)/)
          break
        case ('login:password:ip:port'):
          match = url.match(/(?<login>.*):(?<password>.*):(?<ip>.*):(?<port>.*)/)
          break
        case ('login:password@ip:port'):
        case ('ip:port'):
          return url
        default:
          match = url
      }

      return `${match?.groups?.login}:${match?.groups?.password}@${match?.groups?.ip}:${match?.groups?.port}`
    }

    const generateProxies = () => (proxyMode.value
      ? [{
        url: `${protocol.value}//${username.value}:${password.value}@${host.value}:${port.value}`,
      }]
      : Array.from(
        new Set(proxyValue.value
          .split('\n')
          .filter(el => el)),
      )
        .map(row => ({
          url: `${protocol.value}//${parseUrl(row, parseFormat.value)}`,
        })))

    const submit = async () => {
      const value = proxies.value.map(el => ({
        value: el.url,
        mobile: el.mobile,
        change_ip_url: el.changeIpUrl,
        expired_at: el.expireDate,
        name: el.name,
      }))
      emit('submit', value)
    }

    const checkProxy = () => {
      checkLoading.value = true
      ProxyApi.checkProxy({
        proxies: proxies.value.map(el => el.url),
      }).then(({ data }) => {
        checkLoading.value = false
        Object.entries(data).forEach(([url, checked]) => {
          const prox = proxies.value.find(el => el.url === url)
          const idx = proxies.value.findIndex(el => el.url === url)

          proxies.value.splice(idx, 1, { ...prox, checked })
        })
      })
    }

    const tryNext = async () => {
      if (step.value === 1 && formUrl.value.validate()) {
        const _proxies = generateProxies()
        proxies.value = _proxies
        checkProxy()

        step.value = 2
      } else if (step.value === 2 && formCheck.value.validate() && proxies.value.every(el => el.checked)) {
        submit()
      }
    }

    const removeProxy = index => proxies.value.splice(index, 1)

    const removeInvalidProxies = () => {
      proxies.value = proxies.value.filter(el => el.checked)
    }

    const sortProxies = computed(() => {
      if (proxies.value.some(el => !el.checked)) {
        return proxies.value.sort((a, b) => a.checked - b.checked)
      }

      return proxies.value
    })

    const generateNames = () => {
      proxies.value = proxies.value.map((el, index) => ({ ...el, name: `${proxiesName.value}${index + 1}` }))
    }

    return {
      step,
      tryNext,
      removeProxy,
      previewHeaders,
      parseFormat,
      urlFormats,
      proxies,
      urlChecked,
      supportedProtocol,
      formUrl,
      formOptions,
      formCheck,
      proxyMode,
      changeIpUrl,
      proxyValue,
      port,
      protocol,
      host,
      username,
      password,
      expireDate,
      validate,
      reset,
      required,
      isProxyUrl,
      isTrue,
      submit,
      checkProxy,
      mobile,
      checkLoading,
      name,
      removeInvalidProxies,
      sortProxies,
      proxiesName,
      generateNames,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
        mdiDelete,
        mdiDeleteForever,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.v-alert {
 &.v-alert--dense{
  padding-top: 6.5px;
  padding-bottom: 6.5px;
 }
}
</style>
