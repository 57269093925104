<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="name">Name</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="name"
          v-model="name"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <label for="protocol">Protocol</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-select
          id="protocol"
          v-model="protocol"
          :items="supportedProtocol"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter host"
          :rules="proxyMode === 1 ? [required]: []"
          @change="() => urlChecked = null"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <label for="host">Proxy Host</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="host"
          v-model="host"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter host"
          :rules="proxyMode === 1 ? [required]: []"
          @change="() => urlChecked = null"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <label for="port">Port</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="port"
          v-model.number="port"
          type="number"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter host"
          :rules="proxyMode === 1 ? [simpleRequired]: []"
          @change="() => urlChecked = null"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <label for="host">Username</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          v-model="username"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter username"
          :rules="[]"
          @change="() => urlChecked = null"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <label for="host">Password</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          v-model="password"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter password"
          :rules="[]"
          @change="() => urlChecked = null"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
        class="d-flex align-center"
      >
        <label for="user_agent">Expire Date</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <DatePicker
          v-model="expireDate"
        />
      </v-col>
      <v-col
        md="9"
        offset-md="3"
      >
        <v-switch
          id="mobile"
          v-model="mobile"
          label="Mobile"
        />
      </v-col>
      <template v-if="mobile">
        <v-col
          cols="12"
          md="3"
          class="d-flex align-center"
        >
          <label for="changeIpUrl">Change IP Url</label>
        </v-col>

        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            id="changeIpUrl"
            v-model="changeIpUrl"
          />
        </v-col>
      </template>
      <v-col
        cols="12"
        md="3"
      >
        <label>Test Connection</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <TestProxy
          v-model="urlChecked"
          :url="url"
          :rules="[isTrue]"
        />
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          Testing connection is required
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  required, isProxyUrl, isTrue, simpleRequired,
} from '@core/utils/validation'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import DatePicker from '../DatePicker.vue'
import TestProxy from './TestProxy.vue'

export default {
  components: { DatePicker, TestProxy },
  props: {
    disabled: Boolean,
    proxy: {
      type: Object,
      default: () => {},
    },
  },

  setup({ proxy }, { emit }) {
    const form = ref(null)

    const validate = () => form.value.validate()
    const reset = () => form.value.reset()

    const supportedProtocol = [{
      text: 'HTTP',
      value: 'http:',
    }, {
      text: 'HTTPS',
      value: 'https:',
    }, {
      text: 'SOP2',
      value: 'sop2:',
    }, {
      text: 'SOP3',
      value: 'sop3:',
    }, {
      text: 'socks4',
      value: 'socks4:',
    }, {
      text: 'socks5',
      value: 'socks5:',
    }]

    const proxyValue = ref(proxy.value.replace(/.*\/\//gi, '') || '')
    const expireDate = ref(proxy.expired_at || null)
    const changeIpUrl = ref(proxy.change_ip_url, '')

    const proxyURI = proxy.value.replace(/\/\//gi, '').match(/(?<protocol>.*):(?<login>.*):(?<password>.*)@(?<ip>.*):(?<port>.*)/)
    const host = ref(proxyURI.groups.ip)
    const protocol = ref(`${proxyURI.groups.protocol}:`)
    const port = ref(proxyURI.groups.port)
    const username = ref(proxyURI.groups.login)
    const password = ref(proxyURI.groups.password)
    const name = ref(proxy.name)
    const proxyMode = ref(1)
    const mobile = ref(proxy.mobile)

    const parseFormat = ref('login:password@ip:port')
    const urlFormats = [
      'ip:port:login:password',
      'ip:port@login:password',
      'ip:port|login:password',
      'login:password@ip:port',
      'login:password:ip:port',
      'ip:port',
    ]

    const parseUrl = (url, format) => {
      let match
      switch (format) {
        case ('ip:port:login:password'):
          match = url.match(/(?<ip>.*):(?<port>.*):(?<login>.*):(?<password>.*)/)
          break
        case ('ip:port@login:password'):
          match = url.match(/(?<ip>.*):(?<port>.*)@(?<login>.*):(?<password>.*)/)
          break
        case ('ip:port|login:password'):
          match = url.match(/(?<ip>.*):(?<port>.*)\|(?<login>.*):(?<password>.*)/)
          break
        case ('login:password:ip:port'):
          match = url.match(/(?<login>.*):(?<password>.*):(?<ip>.*):(?<port>.*)/)
          break
        case ('login:password@ip:port'):
        case ('ip:port'):
          return url
        default:
          match = url
      }

      return `${match?.groups?.login}:${match?.groups?.password}@${match?.groups?.ip}:${match?.groups?.port}`
    }

    const url = computed(() => (proxyMode.value
      ? `${protocol.value}//${username.value}:${password.value}@${host.value}:${port.value}`
      : `${protocol.value}//${parseUrl(proxyValue.value, parseFormat.value)}`))

    const urlChecked = ref(null)

    const submit = () => ({
      id: proxy.id,
      value: url.value,
      mobile: mobile.value,
      change_ip_url: changeIpUrl.value,
      expired_at: expireDate.value,
      name: name.value,
    })

    watch(urlChecked, value => {
      emit('update:disabled', !value)
    })

    return {
      parseFormat,
      urlFormats,
      urlChecked,
      supportedProtocol,
      form,
      proxyMode,
      url,
      changeIpUrl,
      proxyValue,
      port,
      protocol,
      host,
      username,
      password,
      expireDate,
      validate,
      reset,
      submit,
      required,
      isTrue,
      isProxyUrl,
      mobile,
      name,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
      simpleRequired,
    }
  },
}
</script>
