<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Export proxies
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <label>
              Select columns to export
            </label>

            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="fields_to_export"
                  value="value"
                  label="Proxy"
                />
                <v-select
                  v-if="fields_to_export.includes('value')"
                  v-model="parseFormat"
                  label="Format"
                  class="mb-2"
                  hide-details="auto"
                  :items="urlFormats"
                />
              </v-col>
            </v-row>
            <v-checkbox
              v-for="column in tableColumns"
              :key="column.value"
              v-model="fields_to_export"
              :value="column.value"
              :label="column.label"
            />
            <v-radio-group
              v-model="exportFormat"
              label="Select export format:"
            >
              <v-radio
                label="csv"
                value="csv"
              ></v-radio>
              <v-radio
                label="txt"
                value="txt"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Export
        </v-btn>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import _pick from 'lodash/pick'
import { mdiClose, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import ConfirmDialog from '../ConfirmDialog.vue'

export default {
  components: { ConfirmDialog },
  props: {
    selectedProxy: {
      type: Array,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
      mdiCheck,
      mdiAlertCircleOutline,
    },
    isOpen: false,
    loading: false,
    exportFormat: 'csv',
    fields_to_export: ['value'],
    urlFormats: [
      'ip:port:login:password',
      'ip:port@login:password',
      'ip:port|login:password',
      'login:password@ip:port',
      'login:password:ip:port',
      'ip:port',
    ],
    parseFormat: '',
    tableColumns: [{ value: 'name', label: 'Name' },
      { value: 'mobile', label: 'Mobile' },
      { value: 'status', label: 'Status' },
      { value: 'expired_at', label: 'Expired at' }],
  }),
  computed: {

  },
  methods: {
    async submit() {
      try {
        this.loading = true
        await this.exportProxy()
      } catch (e) {
        this.$message.error(e)
      }
      this.loading = false
    },

    async exportProxy() {
      if (this.exportFormat === 'csv') {
        this.createCsv(this.selectedProxy)
      } else {
        this.createTxt(this.selectedProxy)
      }
    },
    createCsv(proxies) {
      const rows = proxies
        .map(acc => Object
          .entries(_pick(acc, this.fields_to_export))
          .map(([key, value]) => {
            if (key === 'value') {
              return value && `"${this.changeProxyFormat(value)}"`
            }
            if (key === 'mobile') {
              return value ? '"Mobile"' : '"Not mobile"'
            }

            return value ? JSON.stringify(value) : '""'
          })
          .join(','))
        .join('\n')

      this.exportDownload(rows, 'text/csv', 'proxies.csv')
    },

    createTxt(proxies) {
      const rows = proxies
        .map(acc => Object
          .entries(_pick(acc, this.fields_to_export))
          .map(([key, value]) => {
            if (key === 'value') {
              return value && `${this.changeProxyFormat(value)}`
            }
            if (key === 'mobile') {
              return value ? 'Mobile' : 'Not mobile'
            }

            return value ? JSON.stringify(value).replaceAll('"', '') : ''
          })
          .join(':'))
        .join('\n')
      this.exportDownload(rows, 'text/plain', 'proxies.txt')
    },
    open() {
      this.isOpen = true
    },
    reset() {
      this.isOpen = false
      this.proxy = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    exportDownload(content, type, filename) {
      const blob = new Blob([content], { type })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const elem = window.document.createElement('a')
        elem.href = window.URL.createObjectURL(blob)
        elem.download = filename
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      }
    },
    changeProxyFormat(proxyUrl) {
      const proxy = proxyUrl.replace(/\/\//gi, '') || ''
      const match = proxy.match(/(?<protocol>.*):(?<login>.*):(?<password>.*)@(?<ip>.*):(?<port>.*)/)
      let proxyToExport = ''

      switch (this.parseFormat) {
        case ('ip:port:login:password'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}:${match?.groups?.login}:${match?.groups?.password}`
          break
        case ('ip:port@login:password'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}@${match?.groups?.login}:${match?.groups?.password}`
          break
        case ('ip:port|login:password'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}|${match?.groups?.login}:${match?.groups?.password}`
          break
        case ('login:password:ip:port'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.login}:${match?.groups?.password}:${match?.groups?.ip}:${match?.groups?.port}`
          break
        case ('login:password@ip:port'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.login}:${match?.groups?.password}@${match?.groups?.ip}:${match?.groups?.port}`
          break
        case ('ip:port'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}`
          break
        default:
          proxyToExport = proxyUrl
      }

      return proxyToExport
    },

  },
}
</script>
