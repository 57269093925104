var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"90vw","persistent":""},on:{"click:outside":_vm.tryClose},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Selected proxies "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.tryClose}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"d-flex my-2 align-center justify-space-between"},[_c('TableSearch',{attrs:{"show-filters":_vm.showFilters,"check-filter-toggle":_vm.checkFilterToggle},on:{"update:showFilters":function($event){_vm.showFilters=$event},"update:show-filters":function($event){_vm.showFilters=$event}},model:{value:(_vm.tableOptions.search),callback:function ($$v) {_vm.$set(_vm.tableOptions, "search", $$v)},expression:"tableOptions.search"}})],1),_c('v-data-table',{staticClass:"mt-2",attrs:{"items":_vm.proxies,"headers":_vm.itemHeaders,"hide-default-footer":"","search":_vm.tableOptions.search,"options":_vm.tableOptions,"footer-props":{
          'items-per-page-options': _vm.pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],staticClass:"pa-4 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"label":"Status","placeholder":"Select status","hide-details":"","clearable":"","items":_vm.statusOptions},model:{value:(_vm.tableOptions.status),callback:function ($$v) {_vm.$set(_vm.tableOptions, "status", $$v)},expression:"tableOptions.status"}})],1)],1)],1)]},proxy:true},{key:"item.value",fn:function(ref){
        var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-skeleton-loader',{attrs:{"loading":item.loading,"type":"table-cell"}},[_c('v-chip',{attrs:{"color":_vm.StatusesColorMap[item.status] && _vm.StatusesColorMap[item.status].color}},[_vm._v(" "+_vm._s(_vm.StatusesColorMap[item.status] && _vm.StatusesColorMap[item.status].text)+" ")])],1)]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('unselect',index)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}},{key:"item.mobile",fn:function(ref){
        var value = ref.value;
return [_c('TrueFalse',{attrs:{"value":value}})]}},{key:"footer",fn:function(ref){
        var props = ref.props;
        var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}])})],1)],1),_c('ConfirmDialog',{ref:"updateConfirm",attrs:{"width":400,"z-index":99},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var agree = ref.agree;
        var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Confirm update cancel")]),_c('v-card-text',[_vm._v(" You have unsaved changes, you wanna leave ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":agree}},[_vm._v(" Agree ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }