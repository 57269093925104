<template>
  <v-icon
    v-if="value"
    color="success"
  >
    {{ icons.mdiCheckCircleOutline }}
  </v-icon>
  <v-icon
    v-else
    color="error"
  >
    {{ icons.mdiCloseCircleOutline }}
  </v-icon>
</template>

<script>
import { mdiCheckCircleOutline, mdiCloseCircleOutline } from '@mdi/js'

export default {
  props: {
    value: Boolean,
  },
  data: () => ({
    icons: {
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
    },
  }),
}
</script>

<style>

</style>
