<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Edit Proxy
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <EditProxyForm
          v-if="proxy && proxy.value"
          ref="form"
          :disabled.sync="disabled"
          :proxy="proxy"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="disabled"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import ConfirmDialog from '../ConfirmDialog.vue'
import EditProxyForm from './EditForm.vue'

const DEFAULT_PROXY = {

}

export default {
  components: { ConfirmDialog, EditProxyForm },
  data: () => ({
    icons: {
      mdiClose,
    },
    disabled: true,
    isOpen: false,
    initProxy: DEFAULT_PROXY,
    proxy: DEFAULT_PROXY,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initProxy, this.proxy)
    },
  },
  methods: {
    open(proxy) {
      this.isOpen = true
      this.initProxy = cloneDeep(proxy)
      this.proxy = cloneDeep(proxy)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const data = await this.$refs.form.submit()
          this.$emit('update', data)
          this.reset()
          this.$message.success('Proxy updated successfully')
        } catch (err) {
          this.reset()
          this.$message.error(err)
        }
      }
    },
    reset() {
      this.isOpen = false
      this.proxy = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>
